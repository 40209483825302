import React from "react";
import HeroSection from "./../components/HeroSection";
import ValuesSection from "../components/ValuesSection";
import PropositionSection from "../components/PropositionSection";
import ease from "../assets/img/ease.png";
import safety from "../assets/img/safety.png";
import transparency from "../assets/img/transparency.png";
import inclusion from "../assets/img/inclusion.png";
import innovation from "../assets/img/innovation.png";
import propImg from "../assets/img/about-us.jpeg";
import visionImg from "../assets/img/vision.jpg";
import banner from "../assets/img/about-us.jpg";

function AboutPage(props) {
  const items = [
    {
      title: "Easy Access",
      image: ease,
    },
    {
      title: "Inclusion",
      image: inclusion,
    },
    {
      title: "Safety",
      image: safety,
    },
    {
      title: "Innovation",
      image: innovation,
    },
    {
      title: "Transparency",
      image: transparency,
    },
  ];

  return (
    <>
      <HeroSection
        color="info"
        size="large"
        backgroundImage={banner}
        backgroundImageOpacity={0.9}
        title="About Us"
        subtitle="Klude is a digital wallet system that gives Africans seamless access to financial services. At Klude, we leverage the most innovative approaches, technologies and reliable networks as we beat all odds to bring financial access to you. Klude simply is the future of digital banking in Africa."
      />
      <PropositionSection
        image={propImg}
        title="Our Mission"
        subtitle="To provide every African with easy access to financial services anywhere anytime."
        circleColor="#72c9b5"
      />
      <PropositionSection
        image={visionImg}
        title="Our Vision"
        subtitle="To become a prime gateway of access to financial services for all Africans."
        rowReverse
        circleColor="#74CFEB"
      />
      <ValuesSection
        color="gray"
        size="medium"
        title="Our Values"
        subtitle="These are the tenents on which we stand by daily"
        items={items}
        backgroundImage=""
        backgroundImageOpacity={1}
      />
      {/* <VisionSection
        color="white"
        size="medium"
        image={abstractImage}
        hashLink="#our-values"
      ></VisionSection> */}
    </>
  );
}

export default AboutPage;
