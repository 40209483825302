import React from "react";
import SectionHeader from "./SectionHeader";
import Newsletter from "./Newsletter";

function NewsletterSection(props) {
  return (
    <div className="container">
      <div className="columns is-centered">
        <div className="column">
          <div className="columns ">
            <div className="column">
              <SectionHeader
                title={props.title}
                subtitle={props.subtitle}
                size={3}
                spaced={false}
                className="be-white"
                headerIsPaddingless={true}
              ></SectionHeader>
            </div>
          </div>
          <div className="columns" style={{ marginLeft: "0rem" }}>
            <Newsletter
              parentColor="white"
              buttonText={props.buttonText}
              inputPlaceholder={props.inputPlaceholder}
              subscribedMessage={props.subscribedMessage}
              size="medium"
            ></Newsletter>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsletterSection;
