import React from "react";
import "./BigText.scss";
import Section from "./Section";

const BigText = ({ call, response, size, color, responseColor, callColor }) => {
  return (
    <div className="BigText">
      <Section color={color} size={size}>
        <span className={`has-text-${callColor}`}>{call}</span>
        <span className={`has-text-${responseColor}`}>{response}</span>
      </Section>
    </div>
  );
};

export default BigText;
