import React from "react";
import NewsletterSection from "./NewsletterSection";
import { Link } from "../util/router.js";
import instagram from "../assets/img/instagram.png";
import twitter from "../assets/img/twitter.png";
import facebook from "../assets/img/facebook.png";
import "./Footer.scss";

function Footer(props) {
  return (
    <div className="FooterSection">
      <div className=" hero is-normal styled-section">
        <div className="columns FooterSection__container">
          <div className="column">
            <img width="105px" src={props.logo} alt="Klude logo" />
          </div>
          <div className="column FooterSection__second-column">
            <div>
              <Link to="/products">
                <h3>Products</h3>
              </Link>

              <div>
                <Link to="/company" className="FooterSection__Links">
                  About
                </Link>
              </div>
              <div>
                <Link to="#" className="FooterSection__Links">
                  Press
                </Link>
              </div>
              <div>
                <Link to="#" className="FooterSection__Links">
                  Partners
                </Link>
              </div>
              <div>
                <Link to="#" className="FooterSection__Links">
                  Support
                </Link>
              </div>
            </div>
          </div>
          <div className="column FooterSection__third-column">
            <h3>Company</h3>
            <div>
              <Link to="#" className="FooterSection__Links">
                Pricing
              </Link>
            </div>
            <div>
              <Link to="#" className="FooterSection__Links">
                Enterprise
              </Link>
            </div>
          </div>
          <div className="column is-half" style={{ color: "primary" }}>
            <NewsletterSection
              color="primary"
              size=""
              title="Stay in the know"
              subtitle="Receive our latest articles and feature updates"
              buttonText={<i className="fas fa-arrow-right"></i>}
              inputPlaceholder="Enter your email address"
            ></NewsletterSection>
          </div>
        </div>
        <div className="columns">
          <hr className="hr" />
        </div>
        <div className="columns">
          <div className="column copyright">{props.copyright}</div>
          <div className="column socials">
            <span>
              <img src={instagram} alt="Instagram" />
            </span>
            <span>
              <img src={twitter} alt="Twitter" />
            </span>
            <span>
              <img src={facebook} alt="Facebook" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
