import React, { useState } from "react";
import newsletter from "../util/newsletter.js";
import { useForm } from "react-hook-form";
import "./Newsletter.scss";

function Newsletter(props) {
  const [subscribed, setSubscribed] = useState(false);
  const { handleSubmit, register, errors, reset } = useForm();

  const onSubmit = ({ email }) => {
    // Parent component can optionally
    // find out when subscribed.
    props.onSubscribed && props.onSubscribed();
    // Subscribe them
    newsletter.subscribe({ email }).then(() => {
      setSubscribed(true);
      reset();
    });
  };

  return (
    <>
      {
        <form onSubmit={handleSubmit(onSubmit)}>
          {errors.email && (
            <p className="help is-danger has-text-left">
              {errors.email.message}
            </p>
          )}
          {subscribed && (
            <p className="help is-success has-text-left">
              "Success! Thank you."
            </p>
          )}
          <div className="field is-grouped styled-border">
            <div className="control is-expanded">
              <input
                className={`input is-${props.size} styled-input`}
                style={{ borderRadius: "0%" }}
                name="email"
                type="email"
                placeholder={props.inputPlaceholder}
                ref={register({
                  required: "Please enter an email address",
                })}
              ></input>
            </div>
            <div className="control styled-button">
              <button className="button" type="submit">
                {props.buttonText}
              </button>
            </div>
          </div>
        </form>
      }

      {subscribed === true && <div>{props.subscribedMessage}</div>}
    </>
  );
}

export default Newsletter;
