import React from "react";
import { useRouter } from "./../util/router.js";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import "./RequestAccessSection.scss";

const RequestAccessSection = (props) => {
  const router = useRouter();
  const handleClick = (i, event) => {
    event.preventDefault();
    router.push(i);
  };

  return (
    <div className="RequestAccessSectionContainer">
      <Section
        color={props.color}
        size={props.size}
        backgroundImageOpacity={props.backgroundImageOpacity}
      >
        <div className="container">
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            //   size={1}
            spaced={true}
            headerStyle={{
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "24px",
              letterSpacing: "0.2em",
              color: "#2a9d8f",
            }}
            subtitleStyle={{
              fontSize: "21px",
              fontWeight: "500",
              color: "#264653",
              lineHeight: "42px",
            }}
            className="has-text-centered"
          ></SectionHeader>
          <div className="columns has-text-centered">
            <div className="column has-text-centered">
              <button
                className="button is-primary"
                style={{ padding: "0rem 1rem" }}
                onClick={(event) => handleClick(props.buttonClick, event)}
              >
                Request Access{" "}
                <i
                  className="fas fa-arrow-right"
                  style={{ paddingLeft: "7px" }}
                ></i>
              </button>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default RequestAccessSection;
