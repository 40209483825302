import React from "react";

function FormField(props) {
  const {
    error,
    type,
    inputRef,
    selectOptions,
    radioOptions,
    ...inputProps
  } = props;

  return (
    <div className="field">
      <div className="control">
        {type === "textarea" && (
          <textarea
            className="textarea is-medium"
            ref={inputRef}
            {...inputProps}
          ></textarea>
        )}

        {type === "select" && (
          <>
            <label className="label">Department:</label>
            <div className="select">
              <select ref={inputRef} {...inputProps}>
                {selectOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </>
        )}

        {type === "radio" && (
          <>
            <label className="label">I am interested in:</label>
            <div>
              {radioOptions.map((option) => (
                <label className="radio" key={option}>
                  <input
                    key={option}
                    ref={inputRef}
                    type={type}
                    value={option}
                    {...inputProps}
                  ></input>
                  {option}
                </label>
              ))}
            </div>
          </>
        )}

        {type !== "textarea" && type !== "select" && type !== "radio" && (
          <input
            className="input is-medium"
            ref={inputRef}
            type={type}
            {...inputProps}
          ></input>
        )}
      </div>

      {error && <p className="help is-danger">{error.message}</p>}
    </div>
  );
}

export default FormField;
