function submit(data) {
  const api = process.env.REACT_APP_API;
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const { email, name, phoneNumber, company, position, interest } = data;

  const requestBody = {
    email: email,
    name,
    phoneNumber,
    company,
    position,
    interest,
  };

  const requestOptions = {
    method: "POST",
    mode: "cors",
    headers: myHeaders,
    body: JSON.stringify(requestBody),
    redirect: "follow",
  };

  return fetch(`${api}/bookdemo/`, requestOptions)
    .then((response) => response.text())
    .then((result) => result)
    .catch((error) => error);
}

export default { submit };
