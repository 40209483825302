import React from "react";
import "./Card.scss";

const Card = ({ title, image }) => {
  return (
    <div className="s-card">
      <figure>
        <img src={image} alt={"Klude's " + title + " Value"} />
      </figure>
      <h3>{title}</h3>
    </div>
  );
};

export default Card;
