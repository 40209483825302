function subscribe(data) {
  const api = process.env.REACT_APP_API;
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const { email } = data;

  const requestBody = {
    email: email,
  };

  const requestOptions = {
    method: "POST",
    mode: "cors",
    headers: myHeaders,
    body: JSON.stringify(requestBody),
    redirect: "follow",
  };

  return fetch(`${api}/subscribe/`, requestOptions)
    .then((response) => response.text())
    .then((result) => result)
    .catch((error) => error);
}

export default { subscribe };
