import React from "react";
import SectionHeader from "./SectionHeader";
import Features from "./Features";
import "./FeaturesSection.scss";
import insurance from "../assets/img/debt.png";
import withdraw from "../assets/img/withdraw.png";
import offline from "../assets/img/offline.png";

function FeaturesSection(props) {
  return (
    <div className="FeaturesSection">
      <SectionHeader
        title={props.title}
        subtitle={props.subtitle}
        size={3}
        spaced={true}
        className="has-text-left"
      />
      <Features
        items={[
          {
            title: "Offline Transactions",
            description:
              "Pay and receive funds anywhere anytime even without network signals with your Klude Offline wallets.",
            image: offline,
          },
          {
            title: "Easy Savings and Withdrawal",
            description:
              "Save and withdraw funds fast and easily at any agent location close to you through your Mobile App and USSD.",
            image: withdraw,
          },
          {
            title: "Access to Loans and Insurance",
            description:
              "Get access to micro-loans to fund that next big move of yours.",
            image: insurance,
          },
        ]}
      />
    </div>
  );
}

export default FeaturesSection;
