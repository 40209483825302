import React from "react";
import RequestFormSection from "../components/RequestFormSection";

function RequestForm(props) {
  return (
    <RequestFormSection
      color="white"
      size="medium"
      backgroundImage=""
      backgroundImageOpacity={1}
      title="Request Access"
      subtitle=""
      buttonText="Submit Request"
      showNameField={true}
    ></RequestFormSection>
  );
}

export default RequestForm;
