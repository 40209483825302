import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { useSpring, animated } from "react-spring";
import "./PropositionSection.scss";

const PropositionSection = ({
  title,
  subtitle,
  image,
  shadowTitle,
  stStyle,
  rowReverse,
  circleColor,
}) => {
  const prop = useSpring({
    fontSize: stStyle ? stStyle : "0vw",
    from: { fontSize: "1vw" },
  });
  return (
    <Section>
      <div
        className="columns PropositionSection"
        style={
          rowReverse ? { display: "flex", flexDirection: "row-reverse" } : {}
        }
      >
        <div className="column middle">
          <div className="container">
            <SectionHeader
              title={title}
              size={2}
              spaced={true}
              subtitle={subtitle}
              headerColor="primary"
            ></SectionHeader>
          </div>
        </div>
        <div className="column">
          <div className="section">
            {image ? (
              <>
                <div className="column is-1" />
                <div className="column">
                  <div className="fixed-figure">
                    <figure className="PropositionSection__image image">
                      <img src={image} alt="Illustration" />
                    </figure>
                    {circleColor && (
                      <div
                        className="image-circle"
                        style={{ backgroundColor: circleColor }}
                      >
                        <span className="circle-line"></span>
                        <span className="circle-line"></span>
                        <span className="circle-line"></span>
                        <span className="circle-line"></span>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
        <animated.span style={prop} className="shadow-title">
          {shadowTitle}
        </animated.span>
      </div>
    </Section>
  );
};

export default PropositionSection;
