import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import NewsletterSection from "./NewsletterSection";
import "./HeroSection.scss";
import FloatingBoxes from "./FloatingBoxes";

function HeroSection(props) {
  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container HeroSection">
        <div className="columns is-vcentered is-desktop">
          <div className="column is-5-desktop has-text-centered-touch">
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              size={2}
              spaced={true}
            />
            {props.newsletter && (
              <div className="buttons is-inline-flex">
                <NewsletterSection
                  color="primary"
                  size=""
                  subtitle="Be the first to know when we launch."
                  buttonText={<i className="fas fa-arrow-right"></i>}
                  inputPlaceholder="Enter your email address"
                ></NewsletterSection>
              </div>
            )}
          </div>
          {props.floatingBoxes && <FloatingBoxes />}
          {props.image && (
            <>
              <div className="column is-1" />
              <div className="column">
                <figure className="HeroSection__image image">
                  <img src={props.image} alt="Illustration" />
                </figure>
              </div>
            </>
          )}
        </div>
      </div>
    </Section>
  );
}

export default HeroSection;
