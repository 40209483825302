import React from "react";
import "./Features.scss";

function Features({ items, title, description }) {
  return (
    <div className="Features has-text-centered">
      <div className="columns">
        {items.map((item, index) => (
          <div className="column" key={item.title}>
            <div className="Features__card">
              <div className="card-image">
                <figure className="icon">
                  <img src={item.image} alt={item.title} />
                </figure>
              </div>
              <h3 className="card-title is-size-5 has-text-weight-semibold">
                {item.title}
              </h3>
              <div className="Features__content">{item.description}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Features;
