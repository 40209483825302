import React, { useState } from "react";
import FormStatus from "./FormStatus";
import FormField from "./FormField";
import SectionButton from "./SectionButton";
import requestAccess from "../util/requestAccess.js";
import { useForm } from "react-hook-form";

function RequestForm(props) {
  const [status, setStatus] = useState({ type: "none" });
  const { handleSubmit, register, errors, reset } = useForm();

  const onSubmit = ({
    name,
    email,
    phoneNumber,
    company,
    position,
    interest,
  }) => {
    setStatus({ type: "pending" });

    requestAccess
      .submit({ name, email, phoneNumber, company, position, interest })
      .then(() => {
        reset(); // Reset form
        setStatus({
          type: "success",
          message: "Your request has been sent! We'll get back to you soon.",
        });
      })
      .catch((error) => {
        setStatus({
          type: "error",
          message: "Hmmm, something went wrong. Please try again!",
        });
      });
  };

  return (
    <>
      {status && status.message && (
        <FormStatus type={status.type} message={status.message}></FormStatus>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="field is-horizontal">
          <div className="field-body">
            {props.showNameField && (
              <FormField
                name="name"
                type="text"
                placeholder="Name"
                error={errors.name}
                inputRef={register({
                  required: "Please enter your name",
                })}
              ></FormField>
            )}

            <FormField
              name="email"
              type="email"
              placeholder="Email"
              error={errors.email}
              inputRef={register({
                required: "Please enter your email",
                pattern: /^\S+@\S+$/i,
              })}
            ></FormField>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-body">
            <FormField
              name="phoneNumber"
              type="tel"
              placeholder="Phone Number"
              error={errors.phoneNumber}
              inputRef={register({
                required: "Please enter your phone number",
              })}
            ></FormField>
            <FormField
              name="company"
              type="text"
              placeholder="Company"
              error={errors.message}
              inputRef={register({
                required: "Please enter a message",
              })}
            ></FormField>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-body">
            <FormField
              name="position"
              type="select"
              selectOptions={[
                "Executive Level",
                "Sales",
                "Communication",
                "Business Development",
                "IT",
              ]}
              error={errors.position}
              inputRef={register({
                required: "Please select an option",
              })}
            ></FormField>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-body">
            <FormField
              name="interest"
              type="radio"
              radioOptions={["Partnership", "Becoming a Customer"]}
              error={errors.interest}
              inputRef={register({
                required: "Please select an option",
              })}
            ></FormField>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field">
              <div className="control">
                <SectionButton
                  parentColor={props.parentColor}
                  size="medium"
                  state={status.type === "pending" ? "loading" : "normal"}
                >
                  {props.buttonText}
                </SectionButton>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default RequestForm;
