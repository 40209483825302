import React from "react";
import Card from "./Card";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import "./ValuesSection.scss";

const ValuesSection = ({
  items,
  color,
  size,
  backgroundImage,
  backgroundImageOpacity,
  shadowTitle,
  stStyle,
  title,
  subtitle,
}) => {
  return (
    <Section
      color={color}
      size={size}
      backgroundImage={backgroundImage}
      backgroundImageOpacity={backgroundImageOpacity}
    >
      <div className="columns">
        <div className="column">
          <div className="container">
            <SectionHeader
              title={title}
              size={2}
              spaced={true}
              subtitle={subtitle}
              className="has-text-centered"
            ></SectionHeader>
            <div className="ValuesSection__values">
              {items.map((item) => (
                <Card title={item.title} image={item.image} />
              ))}
            </div>
          </div>
        </div>
        <span
          className="shadow-title"
          style={{ zIndex: 1, marginLeft: "3rem", fontSize: stStyle }}
        >
          {shadowTitle}
        </span>
      </div>
    </Section>
  );
};

export default ValuesSection;
