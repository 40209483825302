import React from "react";
import { useSpring, animated } from "react-spring";
import range from "lodash-es/range";
import "./FloatingBoxes.scss";

const items = range(6);
console.log(items);
const interp = (i) => (r) =>
  `translate3d(${15 * Math.sin(r + (i * 2 * Math.PI) / 1.6)}px, ${
    20 * Math.sin(r + (i * 2 * Math.PI) / 1.6)
  }px, 0`;

const FloatingBoxes = (props) => {
  const { radians } = useSpring({
    to: async (next) => {
      while (1) await next({ radians: 2 * Math.PI });
    },
    from: { radians: 0 },
    config: { duration: 5000 },
    reset: true,
  });

  return (
    <div className="">
      {items.map((i) => (
        <animated.div
          key={i}
          className="float-box"
          style={
            !props.flat ? { transform: radians.interpolate(interp(i)) } : null
          }
        />
      ))}
    </div>
  );
};

export default FloatingBoxes;
