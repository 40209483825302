function submit(data) {
  const api = process.env.REACT_APP_API;
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const { email, name, message } = data;

  const requestBody = {
    email: email,
    subject: `New message from ${name}`,
    name,
    message,
  };

  const requestOptions = {
    method: "POST",
    mode: "cors",
    headers: myHeaders,
    body: JSON.stringify(requestBody),
    redirect: "follow",
  };

  return fetch(`${api}/contactus/`, requestOptions)
    .then((response) => response.text())
    .then((result) => result)
    .catch((error) => error);
}

export default { submit };
