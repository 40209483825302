import React from "react";
import "./../styles/global.scss";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import ContactPage from "./contact";
import RequestAccessPage from "./requestAccess";
import { Switch, Route, Router } from "./../util/router.js";
import NotFoundPage from "./not-found.js";
import Footer from "./../components/Footer";
import "./../util/analytics.js";
import { ProvideAuth } from "./../util/auth.js";
import logo from "../assets/img/logo.png";
import whiteLogo from "../assets/img/logo-white.png";

function App(props) {
  return (
    <ProvideAuth>
      <Router>
        <div>
          <Navbar color="white" spaced={true} logo={logo} />

          <Switch>
            <Route exact path="/" component={IndexPage} />

            <Route exact path="/company" component={AboutPage} />

            <Route exact path="/request" component={RequestAccessPage} />

            <Route exact path="/contact" component={ContactPage} />

            <Route component={NotFoundPage} />
          </Switch>

          <Footer
            color="light"
            size="normal"
            backgroundImage=""
            backgroundImageOpacity={1}
            copyright="Klude Technologies © 2020"
            logo={whiteLogo}
          />
        </div>
      </Router>
    </ProvideAuth>
  );
}

export default App;
