import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import RequestForm from "./RequestForm";
import "./RequestFormSection.scss";

function RequestFormSection(props) {
  return (
    <div className="RequestFormSectionContainer">
      <Section
        color={props.color}
        size={props.size}
        backgroundImage={props.backgroundImage}
        backgroundImageOpacity={props.backgroundImageOpacity}
      >
        <div className="RequestFormSection__container container">
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            spaced={true}
            className="has-text-centered"
          ></SectionHeader>
          <RequestForm
            parentColor={props.parentColor}
            showNameField={props.showNameField}
            buttonText={props.buttonText}
          ></RequestForm>
        </div>
      </Section>
    </div>
  );
}

export default RequestFormSection;
