import React from "react";
import "./Navbar.scss";

function NavbarContainer(props) {
  const { color, spaced, className, ...otherProps } = props;

  return (
    <nav
      className={
        "navbar" +
        " NavbarContainer" +
        (color ? ` is-${color}` : "") +
        (spaced ? " is-spaced" : "") +
        (className ? ` ${className}` : "")
        // scrolled
        //   ? " navOnScroll"
        //   : ""
      }
      {...otherProps}
    >
      {props.children}
    </nav>
  );
}

export default NavbarContainer;
