import React from "react";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import RequestAccessSection from "./../components/RequestAccessSection";
import PropositionSection from "../components/PropositionSection";
import propImg from "../assets/img/propImg.jpg";
import BigText from "../components/BigText";

function IndexPage(props) {
  return (
    <>
      <HeroSection
        color="primary"
        size="large"
        backgroundImageOpacity={1}
        title="Safe and easy access to financial services"
        subtitle="Coming Soon!"
        buttonText="Get Started"
        newsletter
        floatingBoxes
        image=""
      />
      <PropositionSection
        image={propImg}
        title="What we do"
        subtitle="At Klude, we are building tools and technologies to guarantee that every African can easily access financial services anywhere anytime."
        shadowTitle="Easy Access"
        stStyle="15vw"
      />
      <FeaturesSection
        color="white"
        size="medium"
        backgroundImage=""
        title="Our Solutions to Financial Access"
        backgroundImageOpacity={1}
      />
      <BigText
        call="No Network?"
        response="No Problem!"
        size="large"
        color="light"
        callColor="gray"
        responseColor="success"
      />
      <RequestAccessSection
        buttonClick="/request"
        color="white"
        size="medium"
        title="Secure your payments with Klude"
      ></RequestAccessSection>
    </>
  );
}

export default IndexPage;
